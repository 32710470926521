import React from 'react';
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import Layout from './Layout';

const ConditionalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) =>
      modal ? (
        <React.Fragment>
          <Link to={closeTo} style={{ float: 'right' }}>
            Close
          </Link>
          {children}
        </React.Fragment>
      ) : (
        <Layout {...rest}>{children}</Layout>
      )
    }
  </ModalRoutingContext.Consumer>
);

export default ConditionalLayout;
