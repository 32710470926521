module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  primary: '#00AA00',
  primaryLight: '#00FF00',
  primaryDark: '#006600',

  secondary: '#55FFFF',
  secondaryLight: '#99EAFF',
  secondaryDark: '#008EB2',
};
